<template>
  <div class="routine">
    <BodyHeader>
      <template #left> {{ $t("lang_new_process") }} </template>
    </BodyHeader>
    <el-card shadow="hover" class="apptype-card" v-for="(item, i) in list" :key="i">
      <div
        class="apptype-card-header"
        :style="{ borderTopColor: colors[Math.round(Math.random() * colors.length)] }"
      >
        <span>{{ item.text }}</span>
        <span>&nbsp;（{{ item.children.length }}）</span>
      </div>
      <div class="apptype-card-body" v-if="item.children.length > 0">
        <div
          class="app-item"
          @click="jumpFlow(row)"
          v-for="row in item.children"
          :key="row.id"
        >
          <span class="app-item-label">{{ row.text }}</span>
        </div>
      </div>
    </el-card>
  </div>
</template>
<script>
import AppApi from "@/api/application/AppApi";
import BodyHeader from "./components/body-header.vue";
export default {
  data() {
    return {
      colors: [
        "rgb(48,199,144)",
        "rgb(170,106,250)",
        "rgb(252,198,72)",
        "rgb(250,104,99)",
        "rgb(141, 206, 54)",
      ],
      item: {},
      list: [],
      list2: [],
    };
  },
  mounted() {
    this.getData(this.$route.query.cateId);
  },
  methods: {
    getData(id) {
      AppApi.applicationLinkQueryCodeTree({ linkType: 2, type: 0 }).then((reg) => {
        if (reg.code == "1") {
          this.list = reg.data.filter((value) => {
            return value.id == id;
          })[0].children;
          this.list2 = reg.data.filter((value) => {
            return value.id == id;
          })[0].children;
        } else {
          this.$message.error(reg.message);
        }
      });
    },
    search(values) {
      let list = [];
      this.list2.forEach((item, index) => {
        for (let i = 0; i < item.children.length; i++) {
          if (item.children[i].text.includes(values)) {
            list.push(item);
          }
        }
      });
      let last = new Set(list);
      this.list = [...last];
    },
    jumpFlow(item) {
      let path = this.$router.resolve({
        path: "/front2/start",
        query: {
          id: item.id,
        },
      });
      window.open(path.href, "_blank");
    },
  },
  computed: {},
  watch: {},
  components: { BodyHeader },
};
</script>
<style lang="scss" scoped>
.routine {
  background: white;
  height: 100%;
  .apptype-card {
    margin-top: 12px;
    //min-height: 200px;
    background: rgb(245, 245, 245);
    width: 300px;
    margin-left: 20px;
    &:hover {
      background: transparent;
    }

    /deep/ .el-card__body {
      padding: 0px;
    }

    .apptype-card-header {
      font-size: 16px;
      color: #606266;
      padding: 8px 20px;
      text-align: center;
      border-top: 4px solid #ffc62e;
      border-bottom: 1px solid #ebeef5;
      box-sizing: border-box;
    }

    .apptype-card-body {
      padding: 10px;
      color: #606266;
      /*max-height: 200px;*/
      /*overflow-y: scroll;*/
      .app-item {
        padding: 0px 10px;
        cursor: pointer;

        &:hover {
          background: rgba(204, 204, 204, 0.1);
          color: #409eff;
        }

        .app-item-dept {
          float: right;
          color: #409eff;

          &:hover {
            transform: scale(1.5);
          }
        }
      }
    }
  }
}
</style>
