var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "routine" },
    [
      _c("BodyHeader", {
        scopedSlots: _vm._u([
          {
            key: "left",
            fn: function () {
              return [_vm._v(" " + _vm._s(_vm.$t("lang_new_process")) + " ")]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._l(_vm.list, function (item, i) {
        return _c(
          "el-card",
          { key: i, staticClass: "apptype-card", attrs: { shadow: "hover" } },
          [
            _c(
              "div",
              {
                staticClass: "apptype-card-header",
                style: {
                  borderTopColor:
                    _vm.colors[Math.round(Math.random() * _vm.colors.length)],
                },
              },
              [
                _c("span", [_vm._v(_vm._s(item.text))]),
                _c("span", [
                  _vm._v(" （" + _vm._s(item.children.length) + "）"),
                ]),
              ]
            ),
            item.children.length > 0
              ? _c(
                  "div",
                  { staticClass: "apptype-card-body" },
                  _vm._l(item.children, function (row) {
                    return _c(
                      "div",
                      {
                        key: row.id,
                        staticClass: "app-item",
                        on: {
                          click: function ($event) {
                            return _vm.jumpFlow(row)
                          },
                        },
                      },
                      [
                        _c("span", { staticClass: "app-item-label" }, [
                          _vm._v(_vm._s(row.text)),
                        ]),
                      ]
                    )
                  }),
                  0
                )
              : _vm._e(),
          ]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }